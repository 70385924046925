.tabs {
  display: flex;
  margin-top: 0.5rem;
}
.tabs button {
  color: #3b82f6;
  border-bottom: 2px solid #3b82f6;
  padding-bottom: 0.25rem;
}
.tab-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
.tab-buttons button {
  background-color: #e5e7eb;
  color: #374151;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
}
.tab-buttons button.active {
  background-color: #3b82f6;
  color: #ffffff;
}
.card {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}
.card h2 {
  font-size: 1.125rem;
  font-weight: 700;
}
.card p {
  color: #6b7280;
}
.card .tags {
  text-align: right;
}
.card .tags span {
  background-color: #f3f4f6;
  color: #374151;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-left: 0.25rem;
}
.card .tags span.yellow {
  background-color: #fef3c7;
  color: #b45309;
}
.roi {
  margin-top: 1rem;
}
.roi h3 {
  font-weight: 700;
}
.roi .roi-values {
  background-color: #edf2f7;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
}
.roi .roi-values .labels,
.roi .roi-values .values {
  display: flex;
  justify-content: space-between;
}
.roi .roi-values .values {
  font-weight: 700;
  margin-top: 0.25rem;
}
.annualized-roi {
  margin-top: 1rem;
}
.annualized-roi p {
  color: #10b981;
  font-weight: 700;
  font-size: 1.25rem;
}
.aum {
  margin-top: 1rem;
}
.aum p {
  color: #374151;
}
.graph {
  margin-top: 1rem;
}
.follow-button {
  margin-top: 1rem;
}
.follow-button button {
  background-color: #3b82f6;
  color: #ffffff;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
