.learn-more {
  color: #3b82f6;
  margin: 20px 0;
  font-size: 16px;
}

.exchanges {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.exchange-card {
  width: 150px;
  padding: 20px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  text-align: center;
}

.exchange-card img {
  width: 50px;
  height: 50px;
}

.exchange-card .official-broker {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #1e3a8a;
  color: #ffffff;
  border-radius: 5px;
  font-size: 12px;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #3b82f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 24px;
}
span {
  margin-left: 5px;
}
