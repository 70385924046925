/* Container for the entire slider */
.slider-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  height: 300px; /* Set the height of the slider block */
}

/* Wrapper for the slides */
.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%; /* Match the container height */
}

/* Individual slides */
.slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide.active {
  opacity: 1;
}

/* Images */
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Text overlay */
.banner-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
  z-index: 10;
  cursor: pointer; /* Make text look clickable */
  text-decoration: none; /* Remove underline */
}

/* Optional hover effect for the text */
.banner-text:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker background on hover */
  transform: scale(1.05); /* Slightly enlarge the text */
}

/* Dots container */
.slider-dots {
  text-align: center;
  margin: 10px 0;
}

/* Dots */
.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Active dot */
.dot.active {
  background-color: #333;
}

/*  
.slider-container {
  position: relative;
  width: 100%;
  max-width: 1000px;  
  margin: auto;
  overflow: hidden;
  border-radius: 10px;  
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
 
.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;  
  width: 100%;
}
 
.slide {
  flex-shrink: 0;
  width: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.slide img {
  width: 100%;
  height: auto;  
  max-height: 400px;  
  object-fit: cover;  
}
 
.slider-dots {
  text-align: center;
  margin: 10px 0;
}
 
.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.dot.active {
  background-color: #333;
} */

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header img {
  height: 30px;
}
.header .icons {
  display: flex;
  gap: 15px;
}
.header .icons i {
  font-size: 20px;
  color: #333;
}
/* .banner {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.slider {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
}
.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.slides img {
  width: 100%;
  border-radius: 10px;
}
.dots {
  text-align: center;
  padding: 10px 0;
}
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active {
  background-color: #edf2f7;
} */
.announcements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.announcements i {
  font-size: 20px;
  color: #333;
}
.announcements .menu-icon {
  font-size: 20px;
  color: #333;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px 20px;
}
.grid-item {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.grid-item img {
  width: 50px;
  height: 50px;
}
.grid-item p {
  margin: 10px 0 0;
  font-size: 14px;
  color: #333;
}
.bot-status {
  padding: 20px;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px dashed #ff0000;
}
.bot-status .status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.bot-status .status-header .status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.bot-status .status-header .status span {
  font-size: 14px;
  color: #ff0000;
}
.bot-status .status-header .status i {
  font-size: 14px;
  color: #ff0000;
}
.bot-status .status-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bot-status .status-content p {
  font-size: 14px;
  color: #333;
}
.bot-status .status-content .activate-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.connect-exchange {
  text-align: center;
  padding: 20px;
  background-color: #000;
  color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.connect-exchange .bind-api-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.footer {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.footer p {
  margin: 0;
  font-size: 18px;
  color: #333;
}
.footer .crafted {
  font-size: 14px;
  color: #333;
}
.footer .crafted i {
  color: #ff0000;
}
.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
}
.nav-item {
  text-align: center;
  color: #007bff;
  cursor: pointer;
}
.nav-item li a {
  padding-left: 2px;
  font-size: 16px;
  text-decoration: none;
}
.nav-item {
  margin: 5px 0 0;
  font-size: 12px;
  list-style-type: none;
}

.nav-item li svg {
  font-size: large;
}
