/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.header {
  background: linear-gradient(to right, #d4fc79, #96e6a1);
  padding: 20px;
  text-align: center;
  color: white;
}

.header img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.header .info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.header .info h2 {
  margin: 0;
  font-size: 18px;
}

.header .info p {
  margin: 0;
  font-size: 14px;
} */

.activate-section {
  background-color: #ffd700;
  padding: 10px;
  text-align: center;
  color: black;
  font-weight: bold;
}

.activate-section button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.progress-bar {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: white;
}

.progress-bar div {
  text-align: center;
}

.progress-bar .step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  line-height: 30px;
  color: white;
  margin: 0 auto;
}

.progress-bar .step.active {
  background-color: #28a745;
}

.progress-bar .step.completed {
  background-color: #28a745;
}

.progress-bar p {
  margin: 5px 0 0;
  font-size: 12px;
}

.menu {
  background-color: white;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 5px solid #e0e0e0;
  background-color: #edf2f7;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item i {
  margin-right: 10px;
}

.menu-item span {
  flex-grow: 1;
}
span a {
  color: black;
}

/* .footer {
  text-align: center;
  padding: 20px;
  font-size: 12px;
  color: #888;
}

.footer i {
  color: red;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 10px 0;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
}

.nav-bar a {
  text-align: center;
  color: #007bff;
  text-decoration: none;
  font-size: 12px;
}

.nav-bar a i {
  display: block;
  font-size: 20px;
} */
