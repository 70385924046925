.card {
  background-color: white;
  color: black;
  border-radius: 0.5rem;
  padding: 16px;
  margin-top: 16px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-content {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.button {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  border-radius: 0.5rem;
  margin-right: 8px;
}
.history {
  margin-top: 24px;
}
.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.history-record {
  background-color: #edf2f7;
  padding: 16px;
  border-radius: 0.5rem;
  margin-bottom: 16px;
}
/* .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: #2563eb;
  padding: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.footer-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-button i {
  font-size: 1.25rem;
}
.footer-button span {
  font-size: 0.75rem;
} */
.floating-button {
  position: fixed;
  bottom: 64px;
  right: 16px;
  background-color: #2563eb;
  color: white;
  padding: 16px;
  border-radius: 50%;
}
.filter-popup {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.filter-content {
  background-color: white;
  color: black;
  padding: 16px;
  border-radius: 0.5rem;
  width: 256px;
}
.filter-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 16px;
}
.filter-option {
  margin-bottom: 16px;
}
.filter-option label {
  display: flex;
  align-items: center;
}
.filter-option input {
  margin-right: 8px;
}
.apply-button {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  border-radius: 0.5rem;
}
