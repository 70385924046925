.container {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}
.header {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.header i {
  font-size: 24px;
  margin-right: 10px;
}
.header h1 {
  font-size: 20px;
  margin: 0;
}
.back-button {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.content {
  margin-top: 20px;
}
.content h2 {
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.steps {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.steps.collapsed {
  max-height: 0;
}
.steps.expanded {
  max-height: 1000px; /* Adjust as needed */
}
.steps li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.steps li i {
  font-size: 24px;
  margin-right: 10px;
  color: #4a90e2;
}
.steps li p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}
.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.form-group .copy-button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  color: #4a90e2;
  cursor: pointer;
}
.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.checkbox-group input {
  margin-right: 10px;
}
.checkbox-group label {
  font-size: 14px;
}
.proceed-button {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
