/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.header {
  padding: 10px 20px;
  font-size: 24px;
  font-weight: bold;
} */
.connect-exchange {
  background-color: #0d1117;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin: 20px;
}
.connect-exchange button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.tabs {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.tab {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  margin: 0 5px;
  cursor: pointer;
}
.tab.active {
  background-color: #000;
  color: white;
}
.tab.inactive {
  background-color: #f1f1f1;
  color: #000;
}
.sub-tabs {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.sub-tab {
  padding: 10px 20px;
  cursor: pointer;
  color: #007bff;
}
.sub-tab.active {
  border-bottom: 2px solid #007bff;
}
.content {
  text-align: center;
  margin: 50px 0;
  font-size: 18px;
  color: #000;
}
/* .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  border-top: 1px solid #ccc;
}
.footer .icon {
  text-align: center;
  color: #007bff;
}
.footer .icon i {
  font-size: 24px;
}
.footer .icon p {
  margin: 5px 0 0;
  font-size: 12px;
} */
.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}
.hidden {
  display: none;
}
.data-table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}
.data-table th,
.data-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}
.data-table th {
  background-color: #f1f1f1;
}
